.transaction {
    overflow-x: auto;

    table {
        width: 100%;
        border-spacing: 0px;

        thead {
            font-weight: bold;

            td {
                white-space: nowrap;
            }
        }

        tbody {
            td {
                b {
                    white-space: nowrap;
                }
            }
        }

        td {
            padding: 10px 15px;
        }
    }
}