$drawer-width: 230px;
$drawer-width-mini: 65px;
$primary: #1fdd7b;
$secondary: #cbffef;
$bg: #E5E5E5;

@import "../containers/Containers.scss";
@import "../pages/dashboard/dashboard.scss";
@import "../pages/transaction/transaction.scss";

:root {
    .swal2-container {
        z-index: 3000;
    }

    font-size: small;
}

.MuiPopover-paper {
    box-shadow: 0px 0px 3px 3px rgba(144, 144, 144, 0.1) !important;
}

.custom-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .custom-modal-content {
        max-width: 90vw;

        .MuiCardHeader-root {
            span {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .MuiCardContent-root {
            max-height: 90vh;
            overflow: auto;
        }

    }
}

button {
    text-transform: unset !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    white-space: nowrap;

    &.MuiButton-containedSecondary:hover {
        background-color: #fff0cb6b !important;
    }
}

.custom-table-container {
    padding: 0px !important;

    .header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .inp {
            margin-left: 10px;
        }

        form {
            display: inline;
        }
    }

    .body {
        overflow-x: auto;
    }

    table {
        width: 100%;
        border-spacing: 0px;

        thead {
            font-weight: bold;

            td {
                white-space: nowrap;
            }
        }

        tbody {
            td {
                b {
                    white-space: nowrap;
                }
            }

            tr:hover {
                background-color: $secondary;
            }
        }

        td {
            padding: 15px 20px;
        }
    }
}

.custom-table-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 10px;
    padding: 20px !important;

    .rows-per-page {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            width: 300px;
        }

        .options {
            width: 75px;
        }
    }
}

.custom-form {
    width: 60vw;

    .form-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            font-size: 20px;
            font-weight: bold;
        }
    }
}

.form-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.custom-loading {
    svg {
        width: 10vw;
        height: 10vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.clickable {
    cursor: pointer;
}

.status-progress {
    padding: 10px;
    border-radius: 10px;
    color: white;

    &.hold {
        background-color: rgb(212, 189, 63);
    }

    &.running {
        background-color: rgb(47, 4, 220);
    }

    &.complete {
        background-color: rgb(14, 178, 12);
    }
}

.form-table {

    border: 1px solid gainsboro;

    table {
        width: 100%;
        border-spacing: 0px;

        thead {
            font-weight: bold;

            td {
                white-space: nowrap;
            }
        }

        tbody {
            td {
                b {
                    white-space: nowrap;
                }
            }

            tr:hover {
                background-color: $secondary;
            }
        }

        td {
            padding: 15px 20px;
        }
    }
}