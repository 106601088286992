.sidetab {

    @media (min-width: 769px) {
        width: $drawer-width;
    }

    @media (max-width: 768px) {
        width: $drawer-width-mini;
    }

    overflow-x: hidden;

    .brand {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .brand-short {
            background-color: $primary;
            border-radius: 10px;
            padding: 5px;
            margin-right: 10px;
            color: white;
        }
    }

    .MuiListItemButton-root {

        font-weight: bold !important;
        border-left: 6px solid transparent;

        .MuiListItemIcon-root {
            svg {
                color: $primary;
                background-color: $secondary;
                width: 26px;
                height: 26px;
                border-radius: 10px;
                padding: 5px;
            }
        }
    }

    .MuiListItemButton-root.active {

        border-left: 6px solid $primary;

        .MuiListItemIcon-root {
            svg {
                color: $secondary;
                background-color: $primary;
            }
        }
    }


    .MuiListItemButton-root:hover {
        background-color: unset;
    }
}

.main {

    @media (min-width: 769px) {
        margin-left: $drawer-width;
    }

    @media (max-width: 768px) {
        margin-left: $drawer-width-mini;
    }


    header {
        height: 7vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title {
            span {
                font-size: 14px;
                font-weight: bold;
                vertical-align: middle;
                margin-left: 15px;
            }

            svg {
                margin-bottom: 5px;
            }

            nav {
                margin-left: 15px;

                a {
                    vertical-align: top;
                }
            }
        }

        .profile {
            display: flex;
            flex-direction: row;
            align-items: center;

            .profile {
                margin-right: 15px;

                svg {
                    height: 40px;
                    width: 40px;
                    color: $secondary;
                }
            }

            div {
                display: flex;
                flex-direction: column;

                .role {
                    color: rgba(0, 0, 0, 0.38);
                    font-size: 10px;
                }
            }

            button {
                margin: 0px 20px 0px 10px !important;
            }

        }
    }

    .content {
        background-color: $bg;
        height: 93vh;
        overflow: auto;
    }
}