.dashboard {
    .timer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .time {
            margin: 10px;
            font-size: xx-large;
        }

        .start {
            background-color: green;
        }

        .finish {
            background-color: red;
        }
    }
}